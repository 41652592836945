const SensorUtils = {
  getSensorName(sensorShortcut) {
    const sensorDict = this.getSensorDict();
    if (sensorShortcut) {
      const sensor = sensorDict.filter(sensor => sensor.val == sensorShortcut);
      return sensor[0].label;
    } else {
      return "";
    }
  },
  getSensorDict() {
    return [
      {
        val: "SENT2",
        label: "Sentinel-2",
        labelResolution: "Sentinel-2 (10 m)"
      },
      {
        val: "SENT1",
        label: "Sentinel-1",
        labelResolution: "Sentinel-1 (10 m)"
      },
      {
        val: "SENT3",
        label: "Sentinel-3",
        labelResolution: "Sentinel-3 (300 m)"
      },
      { val: "LSAT8", label: "Landsat 8", labelResolution: "Landsat 8 (30 m)" },
      { val: "LSAT7", label: "Landsat 7", labelResolution: "Landsat 7 (30 m)" },
      { val: "LSAT9", label: "Landsat 9", labelResolution: "Landsat 9 (30 m)" },
      { val: "SUDOV", label: "SuperDove", labelResolution: "SuperDove (3 m) " },
      { val: "VIIRS", label: "VIIRS", labelResolution: "VIIRS (750 m)" },
      { val: "SKYST", label: "SKYSAT", labelResolution: "SKYSAT (50 cm)" },
      { val: "ENMAP", label: "EnMap", labelResolution: "EnMap (30 m)" },
      {
        val: "MODAQ",
        label: "Modis Aqua",
        labelResolution: "Modis Aqua (1000 m)"
      },
      { val: "WV2", label: "WorldView2", labelResolution: "WorldView2" },
      { val: "WV3", label: "WorldView3", labelResolution: "WorldView3" },
      { val: "PLN", label: "PléiadesNeo", labelResolution: "PléiadesNeo" },
      { val: "DRONE", label: "Drone", labelResolution: "Drone" },
      { val: "modelled", label: "Modelled", labelResolution: "Modelled" }
    ];
  }
};
export default SensorUtils;
